import NextLink from "next/link";
import {GridElementModal} from "@/components/clapps/Modals";
import Image from 'next/image'
import iconCartImg from '../../../public/assets/clapps/images/icon-cart.svg';
import iconAppImg from '../../../public/assets/clapps/images/icon-app.svg';
import iconWebImg from '../../../public/assets/clapps/images/icon-web.svg';
import iconCrmImg from '../../../public/assets/clapps/images/icon-crm.svg';
import iconLanImg from '../../../public/assets/clapps/images/icon-landing.svg';

function GridElement(props){
    return(
        <div className={'lan2_li'}>
            <h3 className={'lan2_li_h3'}>
                {props.title}
            </h3>
            <p className={'lan2_li_text'}>
                {props.text}
            </p>
            <div className={'lan2_li_block'}>
                <div className={'flex flex-col justify-center'}>
                    <p className={'lan2_li_price'}>
                        {props.price}
                    </p>
                    <NextLink href={props.link} className={'lan2_li_detail'}>подробнее</NextLink>
                </div>
                <div className={'lan2_li_block_img'}>
                    <Image
                        src={props.icon}
                        alt="ICON"
                        quality={100}
                        sizes="100vw"
                        style={{
                            // width: '100%',
                            // height: '100%',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default function LandingSection2(props){
    return(
        <section id="products" className={'lan2_nav'}>
            <div>
                <h2 className={'lan2_h2'}>
                    Что можно заказать:
                </h2>
                <p className={'lan2_p'}>
                    Создадим проект любой сложности, выполним разработку под ключ. Стоимость зависит от многих факторов, но для вас мы рассчитаем её совершенно бесплатно.
                </p>
                <div className={'lan2_ul'}>
                    <GridElement
                        title={'Интернет-магазин'}
                        text={<>Интернет-магазин с интеграцией систем 1С, методов оплаты и доставки. А еще можете <NextLink href={'#solutions'} className={'text-black-4 hover:text-red-1 underline underline-offset-[6px]'}>купить готовый</NextLink>.</>}
                        price={'от 250 000 руб.'}
                        link={'/lp-shop'}
                        icon={iconCartImg}
                    />
                    <GridElement
                        title={'Приложение'}
                        text={'Создание мобильных приложений на системах Android и iOS, разработка приложений для Windows и Linux.'}
                        price={'от 120 000 руб.'}
                        link={'/lp-app'}
                        icon={iconAppImg}
                    />
                    <GridElement
                        title={'Бизнес-сайт'}
                        text={'Сайт для вашего бизнеса, организации, учреждения. Корпоративный портал под ключ в фирменном стиле.'}
                        price={'от 80 000 руб.'}
                        link={'/lp-business'}
                        icon={iconWebImg}
                    />
                    <GridElement
                        title={'CRM-система'}
                        text={'Разработка и внедрение систем CRM, автоматизации бизнеса. Интеграция продуктов 1С, Битрикс 24.'}
                        price={'от 150 000 руб.'}
                        link={'/lp-crm'}
                        icon={iconCrmImg}
                    />
                    <GridElement
                        title={'Landing page'}
                        text={'Сайт-одностраничник, визитка или лендинг. Дизайн под заказ или же недорого на готовом шаблоне.'}
                        price={'от 30 000 руб.'}
                        link={'/lp-landing'}
                        icon={iconLanImg}
                    />
                    <GridElementModal
                        title={'Давайте обсудим ваш проект?'}
                        text={'Ответим на все вопросы и бесплатно рассчитаем стоимость.'}
                        handleOpenModal={props.handleOpenModal}
                    />
                </div>
            </div>
        </section>
    )
}