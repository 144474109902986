import Button from "@mui/material/Button";
import Image from 'next/image'
import markerImg from '../../../public/assets/clapps/images/list-marker.svg';
import section4Bg1Img from '../../../public/assets/clapps/images/section-4-bg-1.svg';
import section4Bg2Img from '../../../public/assets/clapps/images/section-4-bg-2.svg';

function ListElement(props){
    return(
        <div className={'lan4_block_list_el'}>
            <Image 
                src={markerImg}
                alt="MARKER"
                quality={100}
                sizes="100vw"
                style={{
                    width: 'auto',
                    height: 'auto',
                }}
            />
            <h3 className={'lan4_block_list_el_h3'}>{props.text}</h3>
        </div>
    )
}

export default function LandingSection4(props){
    return(
        <section id={'integration'} className={'lan4'}>
            <h2 className={'lan4_h2'}>
                Выполним интеграции сайта:
            </h2>
            <div className={'lan4_block1'}>
                <div className={'lan4_block2'}>
                    <div className={'lan4_block_list'}>
                        <ListElement text={'с системами 1С, Битрикс'}/>
                        <ListElement text={'с сервисами доставки товаров (СДЭК, Почта России)'}/>
                        <ListElement text={'с маркетплейсами (Яндекс.Маркет, Wildberries, Ozon)'}/>
                        <ListElement text={'с платежными системами (YooMoney, Тинькофф, Сбер)'}/>
                        <ListElement text={'с картами (Яндекс.Карты, Google Maps, 2ГИС)'}/>
                        <ListElement text={'с уже работающей в вашей компании CRM'}/>
                    </div>
                    {!props.screenMobile && (
                        <Image
                            src={section4Bg1Img}
                            alt="PUZZLE"
                            quality={100}
                            sizes="100vw"
                            style={{
                                width: 'auto',
                                height: 'auto',
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={'lan4_block_q'}>
                Остались вопросы? Мы позвоним и расскажем подробнее про любые возможные интеграции
            </div>
            <div className="lan4_block_button_div">
                <Button className={'lan4_block_button'} onClick={props.handleOpenModal}>
                    Заказать обратный звонок
                </Button>
            </div>
            <div className={'lan4_block_accept'}>
                Нажимая кнопку «Заказать обратный звонок» я соглашаюсь с политикой конфиденциальности и обработки персональных данных
            </div>
            {!props.screenMobile && (
                <div className={'absolute bottom-0 px-5'}>
                    <Image
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                        src={section4Bg2Img}
                        alt="BG"
                        quality={100}
                    />
                </div>
            )}
        </section>
    )
}