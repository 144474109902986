import NextLink from "next/link";
import {GridElementModal} from "@/components/clapps/Modals";
import clsx from "clsx";

function GridElement(props){
    return(
        <div className={'lan3_el'}>
            <h3 className={'lan3_el_h3'}>
                {props.title}
            </h3>
            <p className={'lan3_el_p'}>
                {props.text}
            </p>
            <div className={'lan3_el_block'}>
                <div className={'lan3_el_block2'}>
                    <p className={'lan3_el_block2_price'}>
                        {props.price}
                    </p>
                    {props.badge && (
                        <p className={clsx(props.badge.className, 'lan3_el_block2_p')}>
                            {props.badge.text}
                        </p>
                    )}
                </div>
            </div>
            <div className={'mt-4 sm:mt-8'}>
                {props.link && (
                    <NextLink href={props.link} className={'mt-4 xl:mt-8 text-sm sm:text-lg xl:text-22px text-black-1 hover:text-red-1 underline underline-offset-[6px]'}>подробнее</NextLink>
                )}
            </div>
        </div>
    )
}

export default function LandingSection3(props){
    return(
        <section id={'solutions'} className={'lan3'}>
            <h2 className={'lan3_h2'}>
                Готовые решения
            </h2>
            <p className={'lan3_p'}>
                Хотите купить готовый сайт или решение для сайта? <br/> Выберите подходящий вариант для вашего предприятия:
            </p>
            <div className={'lan3_grid'}>
                <GridElement
                    title={'Интернет-витрина'}
                    text={'Каталог товаров с ручным наполнением. Дополнительные интеграции по запросу. Самый быстрый старт.'}
                    price={'от 80 000 руб.'}
                    // link={'/'}
                    badge={{
                        text: 'хит продаж',
                        className: 'yellow-notch-bg'
                    }}
                />
                <GridElement
                    title={'Интернет-магазин'}
                    text={'Готовый интернет-магазин с полным набором интеграций: системы 1С, методы оплаты, доставки и т.д.'}
                    price={'от 150 000 руб.'}
                    link={'/lp-buy'}
                    badge={{
                        text: props.screenMobile ? 'выгодно' : 'выгодное решение',
                        className: 'blue-notch-bg'
                    }}
                />
                <GridElement
                    title={'Web-сервис 1с: (унф)'}
                    text={'Настройка обмена 1С: УНФ. Методы обмена между интернет-магазином и 1С без запуска программы.'}
                    price={'от 60 000 руб.'}
                    // link={'/'}
                />
                <GridElementModal
                    title={'Хотите купить готовое решение?'}
                    text={'Оставьте свой номер телефона, и мы свяжемся совсем скоро.'}
                    handleOpenModal={props.handleOpenModal}
                />
            </div>
        </section>
    )
}